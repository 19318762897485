import { useState } from 'react';

import { Drop, DropBtn, DropItem, DropList, DropMenu, DropToggle } from '@playbooks/interface/drops';
import { capitalize } from 'utils';

const SelectDrop = ({
	id,
	type = 'border',
	value,
	prevIcon = 'filter',
	placeholder = '',
	options,
	taskRunning = false,
	onChange,
	hasClear = false,
	tailwind,
}) => {
	const [open, setOpen] = useState(false);

	// Methods
	const onClose = () => setOpen(false);

	const onToggle = () => setOpen(!open);

	const onClick = option => {
		setOpen(false);
		onChange(option);
	};

	// Render
	return (
		<Drop open={open} setOpen={onClose}>
			<DropToggle
				id={id}
				type={type}
				display='flex-start'
				prevIcon={prevIcon}
				taskRunning={taskRunning}
				onClick={onToggle}
				span={{ display: 'flex-start', flex: 'grow', minHeight: 'min-h-[20px]' }}
				{...tailwind?.btn}>
				{value ? `${capitalize(value)}` : placeholder}
			</DropToggle>
			<DropMenu open={open} location='right-0' maxHeight='max-h-[300px]'>
				<DropList>
					{options?.map((option, i) => (
						<DropItem key={i}>
							<DropBtn
								nextIcon={value === option ? 'check' : ''}
								onClick={() => onClick(option)}
								className='flex-between w-full'>
								{capitalize(option)}
							</DropBtn>
						</DropItem>
					))}
					<DropItem>
						{hasClear && (
							<DropBtn prevIcon='xmark' onClick={() => onClick('')} className='w-full'>
								Clear
							</DropBtn>
						)}
					</DropItem>
				</DropList>
			</DropMenu>
		</Drop>
	);
};

export { SelectDrop };
