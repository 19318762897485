import { AccentBtn } from '@playbooks/interface/buttons';
import { FadIcon } from '@playbooks/interface/icons';
import { Tag, TagActions, TagBody, TagImg } from '@playbooks/interface/tags';

export const SearchTag = ({ result, options, onRemove }) => (
	<Tag size='xxs' spacing=''>
		{result.thumbnail ? <TagImg size='w-4 h-4' src={result.thumbnail} /> : <FadIcon icon={options.icon} />}
		<TagBody>{result[options.keyName || 'name']}</TagBody>
		<TagActions>
			<AccentBtn size='xxs' icon='xmark' onClick={() => onRemove(result)} />
		</TagActions>
	</Tag>
);
